import { isEmptyNums, isNegativeCustom, isInvalidNums, isNotPercentage } from '@/js/lib/input-check.js'
import errorText from '@/js/lib/error-handle-text.js'
import graph from '@/js/lib/graph.js'
import _ from 'lodash'
var jStat = require('jstat').jStat
var chi = require('chi-squared')

const chiComputed = (data) => {
    var { parsedDf, parsedZ, parsedprob } = data
    // prob
    const prob = chi.cdf(parsedZ, parsedDf)
    // x2
    const tempParsedp = 1 - parsedprob
    const x2 = jStat.chisquare.inv(tempParsedp, parsedDf)

    return { prob, x2 }
}

const fComputed = (data) => {
    var { parsedDf, parsedDf2, parsedZ, parsedprob } = data
    // prob
    const prob = jStat.centralF.cdf(parsedZ, parsedDf, parsedDf2)
    // x2
    const tempParsedp = 1 - parsedprob
    const x2 = jStat.centralF.inv(tempParsedp, parsedDf, parsedDf2)

    return { prob, x2 }
}

const getComputed = (data, computeType) => {
    var output
    if (computeType === 'chi') {
        output = chiComputed(data)
    } else if (computeType === 'f') {
        output = fComputed(data)
    }
    return output
}

const calculateData = (data) => {
    // Note: z represents (x2 | F) in calculations
    var { z, df, df2, prob, computeType } = data
    var output = {}
    var parsedDf = Number(df)
    var parsedDf2 = Number(df2)
    var parsedZ = Number(z)
    var parsedprob = Number(prob)

    var parsedData = { parsedDf, parsedDf2, parsedZ, parsedprob }
    var computedVals = getComputed(parsedData, computeType)

    // Probability Calculation
    if (z !== '' && prob === '') {
        if (isEmptyNums(z)) { return }
        if (isNegativeCustom(parsedZ)) {
            alert(errorText.distributionAlert.FAlert)
            return
        }
        if (isInvalidNums(parsedZ)) { return }

        var outputpValue = 1 - computedVals.prob
        var pOutput = outputpValue.toFixed(4)

        let posHighSkew = parsedZ - Math.abs(pOutput / parsedZ)
        posHighSkew = 6 - 6 * pOutput
        if (pOutput < 0.001) {
            posHighSkew = 7
        }
        const seriesPoint = graph.getIndexPoint(posHighSkew)

        _.set(output, 'data', {
            prob: pOutput,
            z,
            seriesPoint,
            posHighSkew
        })

        // z Calculation
    } else if (z === '' && prob !== '') {
        if (isNotPercentage(parsedprob)) { return }
        if (isInvalidNums(parsedprob)) { return }

        var x2Value = computedVals.x2
        var x2output = x2Value.toFixed(3)

        var posHighSkew = x2output - Math.abs(parsedprob / x2output)
        posHighSkew = 6 - 6 * parsedprob
        if (parsedprob < 0.001) {
            posHighSkew = 7
        }
        const seriesPoint = graph.getIndexPoint(posHighSkew)

        _.set(output, 'data', {
            z: x2output,
            prob,
            seriesPoint,
            posHighSkew
        })
    }

    output = _.mapValues(output.data, _.toNumber)
    return output
}

export default {
    calculateData,
}
