export const tips = {
    general: {
        picker: 'Click for Other Inequality Options',
        prob: 'Probability Value'
    },

    binomial: {
        n: 'Number of trials',
        p: 'Probability of a Success',
        x: 'Number of Successes',
        bLow: 'Smaller Number of Successes',
        aHigh: 'Larger Number of Successes',
    },

    normal: {
        Z: {
            μ: 'Population Mean',
            σ: 'Population St Dev',
            z: 'Z value',
            zLow: 'Smaller Z Value',
            zHigh: 'Larger Z Value',
        },
        X: {
            μ: 'Population Mean',
            σ: 'Population St Dev',
            z: 'X value',
            zLow: 'Smaller X Value',
            zHigh: 'Larger X Value',
        },
        X̄: {
            μ: 'Population Mean',
            σ: 'Population St Dev',
            n: 'Sample Size 30 or Larger',
            z: 'X̄ value',
            zLow: 'Smaller X̄ Value',
            zHigh: 'larger X̄ Value',
        }
    },

    tDistribution: {
        df: 'Degrees of Freedom',
        z: 't value',
        zLow: 'Smaller t Value',
        zHigh: 'Larger t Value',
    },

    chiSquareDistribution: {
        df: 'Degrees of Freedom',
        z: '𝛘² Value',
    },

    fDistribution: {
        df1: 'First Degrees of Freedom',
        df2: 'Second Degrees of Freedom',
        z: 'F Value',
    }
}
